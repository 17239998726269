<template>
  <div class="h-screen w-screen">
    <div class="flex flex-wrap-reverse mx-4 my-8 md:mx-6 lg:mx-8 shadow-2">
      <div
        class="w-full lg:w-6 px-0 py-4 lg:p-7 hidden md:block"
        style="background-image: radial-gradient(circle, #432baa 1%, #0D004C 60%)"
      >
        <img
          src="/images/logos/logo-symbol-for-dark-bg.svg"
          alt="Image"
          height="35"
          class="mb-6 ml-3 lg:ml-0"
        >
        <Carousel
          :value="features"
          :autoplay-interval="15000"
          circular
          :next-button-props="navButtonAttributes"
          :prev-button-props="navButtonAttributes"
        >
          <template #item="slotProps">
            <div class="text-center mb-8">
              <img
                :src="'/images/' + slotProps.data.image"
                alt="Image"
                class="mb-6 w-6"
              >
              <div class="mx-auto font-medium text-xl mb-4 text-white">
                {{ slotProps.data.title }}
              </div>
              <p class="m-0 text-white line-height-3">
                {{ slotProps.data.text }}
              </p>
            </div>
          </template>
        </Carousel>
      </div>
      <div class="w-full lg:w-6 p-4 lg:p-7 surface-card">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const navButtonAttributes = {
  class: 'text-0 hover:bg-yellow-500'
}

const features = ref([
  { title: 'Snadná digitalizace', image: 'feature-illustration-1.svg', text: 'Realizace řešení snadno & rychle!' },
  { title: 'Kontrola', image: 'feature-illustration-2.svg', text: 'Váš business pod dohledem odkudkoliv!' },
  { title: 'Automatizace na míru', image: 'feature-illustration-3.svg', text: 'Automatizace přesně dle Vašich business potřeb!' },
]);
</script>

<style>
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: #FFCC05;
}
</style>
